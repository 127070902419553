.taskboard-list .mat-mdc-chip-list .mat-chip.mat-mdc-standard-chip.mat-chip-selected,
.taskboard-list .mat-chip-list .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
    background-color: #91ffc7;
    color: #0b7641;
    font-size: 11px;
    font-weight: 600;
    padding: 0 12px;
    min-height: 24px;
}

.taskboard-list.mat-mdc-list-base .mat-mdc-list-item .mdc-list-item__primary-text > *,
.taskboard-list.mat-list-base .mat-list-option .mdc-list-item__primary-text > * {
    margin: 3px 0;
    font-size: 13px;
}

.taskboard {
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    padding: 1rem;

    .mdc-list-item__content {
        padding: 0 !important;
        width: 100%;
    }
}

div.taskboard::-webkit-scrollbar {
    display: initial;
    visibility: visible;
    border-radius: 10px;
    height: 10px !important;
    background-color: #cccccc;
}

div.taskboard::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
    border-radius: 10px;
}

div.taskboard::-webkit-scrollbar-thumb {
    background: 0 0;
    background-color: rgba(0, 0, 0, 0.2);
    border: 2px solid transparent;
    border-radius: 10px;
    background-clip: padding-box;
}

.taskboard .taskboard-wrapper {
    width: 229px;
    min-width: 229px;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    box-sizing: border-box;
    display: inline-block;
    vertical-align: top;
    height: 100%;
}

.taskboard .taskboard-list {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    white-space: normal;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 0.25rem;
    margin-top: 10px;
    padding: 10px;
    min-height: 360px;
    height: auto;

    .taskboard-task {
        margin-bottom: 10px;
        border-radius: 0.25rem;
        height: auto;
        padding: 10px;
        overflow: unset;

        .mdc-evolution-chip__graphic {
            display: none;
        }
    }
}

.taskboard .taskboard-cards {
    padding: 0.5rem;
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: auto;
}

.taskboard .taskboard-task.mat-mdc-list-item.mat-2-line {
    position: relative;
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
    padding: 0.5rem;
    margin-bottom: 0.75rem;
    height: auto;
}

.taskboard-list.mat-mdc-list-base .mat-mdc-list-item .mdc-list-item__primary-text .mat-line {
    white-space: normal;
    overflow: visible;
    font-size: 13px;
    word-break: break-word;
    line-height: 13px;
    font-family: 'Open Sans', sans-serif;
    color: rgba(0, 0, 0, 0.87);
    text-wrap: wrap !important;

    &.link-popup {
        color: #007ad9;
        display: flex;
        align-items: center;
    }
}

.taskboard .taskboard-task-title {
    margin-bottom: 0.5rem;
}
