:root {
    .mat-mdc-form-field {
        --mdc-outlined-text-field-outline-color: rgba(0, 0, 0, 0.12);
        --mdc-outlined-text-field-hover-outline-color: rgba(0, 0, 0, 0.12);

        &:hover {
            --mdc-outlined-text-field-outline-width: 2px;
        }
    }
}

.mdc-notched-outline__notch {
    border-right: none;
}
