.mat-mdc-raised-button .mdc-button__label {
    z-index: 0 !important;
    font-family: 'Open Sans', sans-serif;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0.1px;
}

.mat-mdc-raised-button.mat-mdc-button-disabled {
    color: rgba(0, 0, 0, 0.26) !important;
    background: rgba(0, 0, 0, 0.12) !important;
}
