@font-face {
    font-family: 'icomoon';
    src: url('../icon/icofont/fonts2/icomoon.eot?pyh79');
    src:
        url('../icon/icofont/fonts2/icomoon.eot?pyh79#iefix') format('embedded-opentype'),
        url('../icon/icofont/fonts2/icomoon.ttf?pyh79') format('truetype'),
        url('../icon/icofont/fonts2/icomoon.woff?pyh79') format('woff'),
        url('../icon/icofont/fonts2/icomoon.svg?pyh79#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^='icon-'],
[class*=' icon-'] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-new-survey:after {
    content: '\e905';
}
.icon-survey-templates:after {
    content: '\e900';
}
.icon-ads-manager:after {
    content: '\e901';
}
.icon-menu-setup:after {
    content: '\e902';
}
.icon-menu-specials:after {
    content: '\e903';
}
/* .icon-menu-templates:after {
  content: "\e904";
} */
.icon-menu-templates:after {
    content: '\e904';
}
.icon-recipe-manager:after {
    content: '\e911';
}
.icon-report-card:after {
    content: '\e912';
}
.icon-survey-coupon:after {
    content: '\e913';
}
.icon-survey-report:after {
    content: '\e914';
}
