/*

File: scss
*/

/*==============================================================
 For all pages
 ============================================================== */

/*******************
 Main container
*******************/

.main-container {
    display: block;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.mat-drawer-container {
    background: $bodycolor;
}

html .page-wrapper {
    height: calc(100vh - 70px);
    z-index: 2;
    .page-content .padding {
        padding: 15px;
    }
}
.mat-mdc-card-title.flex {
    width: fit-content;
    margin: 0 auto 10px auto;
    display: flex;
    font-weight: 600;
}
.mat-grid-tile {
    padding: 5px;
    border-style: solid;
    // border-width: 1px;
    border-color: $border-tiles;
    span {
        align-self: flex-start;
        font-size: 0.8em;
        padding-top: 3px;
    }
}
.mat-grid-tile.one {
    border-width: 0 1px 0 0;
}
.mat-grid-tile.two {
    border-width: 0 0 0 0;
}
.mat-grid-tile.three {
    padding-top: 5px;
    border-width: 1px 1px 0 0;
}
.mat-grid-tile.four {
    padding-top: 5px;
    border-width: 1px 0 0 0;
}
.mat-ink-bar {
    //height:45px !important;
    // margin-bottom:13px;
    display: none;
}

// .mat-tab-label-active .mat-tab-label-content{
//   color:white;
// }
.mat-mdc-list-item .one,
.two,
.three {
    width: 33%;
}

.mat-mdc-list-item .mat-icon {
    float: right;
}
.mat-expansion-panel-body {
    /*padding:0 0 16px 0 !important*/
    padding: 0px !important;
}
.mat-mdc-card-content {
    padding: 0 !important;
}
.mat-mdc-tab-header {
    padding: 8px 15px;
}
.project_name_list {
    .mat-mdc-list-item {
        height: 33% !important;
        padding: 5px;
    }
}
/*******************
 Login register
*******************/
.login-register {
    height: 100vh;
    display: flex;
    background-size: cover;
    align-items: center;
    .mat-mdc-card {
        margin: 0px 15px;
    }
    .mat-mdc-form-field {
        width: 100%;
    }
    .login-register-box {
        max-width: 400px;
        min-width: 280px;
        margin: 0 auto;
        vertical-align: middle;
    }
    .support-text {
        margin-top: -15px;
        display: block;
        padding-bottom: 15px;
    }
}
.error-card {
    h1 {
        font-size: 210px;
        font-weight: 900;
        line-height: 210px;
        margin: 0px;
        text-shadow: 4px 5px 1px #b3bbbf;
    }
}

.ngx-datatable.material {
    box-shadow: none;
    border: 1px solid $border;
}
/*******************
 Calendar page
*******************/
.cal-event-action {
    text-decoration: none;
    margin: 0 3px;
}
.cal-header {
    border-left: 1px solid #e1e1e1;
    border-right: 1px solid #e1e1e1;
}

.act {
    color: white;

    &:hover {
        color: aqua;
    }
}
/*******************
 Mail page
*******************/
.icon-box {
    width: 20%;
    display: inline-block;
}
/*******************
 Mail page
*******************/
.email-box {
    border: 1px solid $border;
}
.email-sidebar {
    border-right: 1px solid $border;
    width: 300px;
    .d-flex {
        display: flex;
        padding: 15px 0px;
        cursor: pointer;
    }
    .selected {
        .d-flex {
            background: $light;
        }
    }
    .icon-avatar {
        width: 60px;
        text-align: center;
        flex-shrink: 0;
    }
    .mail-title {
        font-weight: 600;
    }
    .text-ellipsis {
        white-space: nowrap;
        overflow: hidden;
        max-width: 200px;
        text-overflow: ellipsis;
    }
    .avatar {
        width: 40px;
        border-radius: 100%;
    }
}

.email-right-panel {
    padding: 30px;
    background: $light;
    .avatar-lg {
        width: 100px;
        border-radius: 100%;
    }
    .row {
        overflow: hidden;
    }
    .col-md-2 {
        width: 20%;
        padding: 10px;
        float: $lft;
    }
}
.align-items-center {
    align-items: center;
}
.bottom-right {
    position: absolute !important;
    right: 40px;
    bottom: 40px;
    z-index: 10;
}
.chat-right-panel {
    .top-avatar {
        img {
            width: 40px;
            vertical-align: middle;
        }
    }
}
.chat-middle-box {
    border-left: 1px solid $border;
    background: $white;
    margin-bottom: 0px !important;
}
.chat-footer {
    padding: 10px 15px !important;
    background: $white;
    border-top: 1px solid $border;
    border-left: 1px solid $border;
}
.chat-list {
    overflow: hidden;
    box-sizing: border-box;
    width: 100%;
    margin-bottom: 1rem;
    padding: 0 2rem;
    display: flex;
    &.even {
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        -moz-justify-content: flex-end;
        justify-content: flex-end;
        text-align: right;
    }
}
.chat-block {
    display: flex;
    align-items: center;
    padding: 15px;
    background: $light;
    border-radius: 6px;
    img {
        vertical-align: middle;
        margin-right: 10px;
    }
}
.chat-date {
    font-size: 12px;
    color: $muted;
}

/*******************
Chartistt chart css
******************/
.barchrt {
    .ct-series-a .ct-bar {
        stroke: $success;
    }
    .ct-series-b .ct-bar {
        stroke: $info;
    }
}

.linearea {
    height: 280px;
    .ct-series-a .ct-area {
        fill-opacity: 0.05;
        fill: $success;
    }
    .ct-series-a .ct-line,
    .ct-series-a .ct-point {
        stroke: $success;
        stroke-width: 2px;
    }
    .ct-series-b .ct-area {
        fill: $info;
        fill-opacity: 0.1;
    }
    .ct-series-b .ct-line,
    .ct-series-b .ct-point {
        stroke: $info;
        stroke-width: 2px;
    }
    .ct-series-c .ct-area {
        fill: $danger;
        fill-opacity: 0.1;
    }
    .ct-series-c .ct-line,
    .ct-series-c .ct-point {
        stroke: $danger;
        stroke-width: 2px;
    }
    .ct-series-a .ct-point,
    .ct-series-b .ct-point,
    .ct-series-c .ct-point {
        stroke-width: 6px;
    }
}
.piechart {
    .ct-series-a .ct-slice-donut {
        stroke: $info;
    }
    .ct-series-b .ct-slice-donut {
        stroke: $success;
    }
    .ct-series-c .ct-slice-donut {
        stroke: $purple;
    }
    .ct-series-d .ct-slice-donut {
        stroke: $light;
    }
    .ct-series-e .ct-slice-donut {
        stroke: $warning;
    }
}

/*******************
Pricing scss
******************/
html {
    .popular-plan {
        transform: scale(1.1);
        z-index: 1;
    }
    .price-listing {
        margin-bottom: 30px;
        .mat-mdc-list-item {
            height: auto;
            .mdc-list-item__content {
                display: block;
                padding: 20px 0;
            }
        }
    }
}
#active_title {
    display: flex;
    height: 55px;
    background-color: $sidebar;
    color: $sidebar-selected-item;
}
#title-inner {
    display: flex;
    align-self: center;
    padding-left: 8px;
    * {
        padding: 22px;
    }
}
.primary-color {
    color: $primary;
}
.symbol {
    margin-right: 10px;
}
.mr5 {
    margin-right: 5px;
}
.custom-green {
    color: $custom-green;
    // background-color:$custom-green;
}
.custom-pink {
    color: $custom-pink;
}
.dashboard-title {
    background-color: $dashboard-title !important;
    height: 44px !important;
}
.zero {
    font-weight: 600;
    font-size: 1.3em !important;
}
.more_info {
    font-size: 1em !important;

    a {
        color: $primary;
        text-decoration: none;
        position: relative;
    }
    a:after {
        content: '';

        width: 100%;
        position: absolute;
        left: 0;
        bottom: 1px;

        border-width: 0 0 1px;
        border-style: solid;
    }
}
// compose mailbox chips hover

.user_popover {
    padding: 1rem;
    background-color: #ffffff;
    min-width: 300px;
    box-shadow: 0 0 10px rgba(51, 51, 51, 0.2);
    .user_details_wrap {
        display: flex;
        align-items: center;
        margin-bottom: 0.875rem;
        .emailInfoImg {
            width: 42px;
            margin-right: 0.875rem;
            height: 42px;
            background-color: #c6c3bd;
            border-radius: 10px;
            img {
                max-width: 100%;
                border-radius: 10px;
            }
        }
        .user_details {
            display: flex;
            flex-direction: column;
            color: rgba(#333333, 0.8);
            > span {
                line-height: 1.2;
            }
        }
    }
    ._info {
        ._info_li {
            display: flex;
            color: rgba(#333333, 0.8);
            padding: 0.5rem 0;
            ._icon {
                width: 42px;
                text-align: center;
                margin-right: 0.875rem;
            }
        }
    }
}
