.mat-mdc-tab-labels {
    height: 100% !important;

    .mat-mdc-tab {
        height: 100% !important;
        max-height: 36px;
    }

    .mdc-tab-indicator__content {
        display: none;
    }
}

.mdc-tab__ripple,
.mat-mdc-tab-ripple {
    display: none;
}

:root {
    .mat-mdc-tab {
        --mat-tab-header-inactive-label-text-color: #000;
        --mat-tab-header-inactive-hover-label-text-color: #000;
        --mat-tab-header-inactive-focus-label-text-color: #000;
    }

    .mat-mdc-tab-nav-bar {
        --mdc-tab-indicator-active-indicator-color: #ffffff00 !important;
        --mat-tab-header-active-focus-indicator-color: #ffffff00 !important;
        --mat-tab-header-active-hover-indicator-color: #ffffff00 !important;
        --mat-tab-header-inactive-label-text-color: #000;
        --mat-tab-header-inactive-focus-label-text-color: #000;
        --mat-tab-header-inactive-hover-label-text-color: #000;
    }
}
