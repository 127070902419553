mat-paginator {
    .mat-mdc-form-field {
        max-width: 60px;
    }

    .mat-mdc-text-field-wrapper {
        padding: 0 5px;
    }

    .mat-mdc-form-field-focus-overlay,
    .mat-mdc-text-field-wrapper {
        background: transparent !important;
    }

    .mat-mdc-icon-button {
        padding: 0 !important;

        .mdc-icon-button__ripple,
        .mat-ripple {
            display: none;
        }

        svg.mat-mdc-paginator-icon {
            width: 28px !important;
            height: 28px !important;
        }
    }

    .mat-mdc-paginator-range-actions {
        height: 20px;
    }

    .mat-mdc-paginator-range-label,
    .mat-mdc-paginator-page-size-label {
        font-family: 'Open Sans', sans-serif;
        font-size: 12px;
        font-weight: 300;
        color: rgba(0, 0, 0, 0.54);
    }

    .mat-mdc-paginator-range-label {
        margin-left: -8px !important;
        margin-right: 0 !important;
    }

    .mat-mdc-paginator-page-size {
        margin-top: 0 !important;
        margin-bottom: 5px !important;

        .mat-mdc-form-field-infix {
            min-height: 16px !important;
            padding-top: 0 !important;
            padding-bottom: 0 !important;
        }
    }
}
