.fc-scroller th {
    background-color: #f4f4f4;
    border: 1px solid #c8c8c8;
    padding: 0.571em 1em !important;

    a {
        color: #333333;
        cursor: default;
        text-decoration: none !important;
        padding: 0 !important;
    }
}

.fc .fc-daygrid-day-number {
    color: rgba(0, 0, 0, 0.87);
}

.fc td.ui-widget-content {
    background-color: #ffffff;
    border: 1px solid #c8c8c8;
    color: #333333;
}

.fc td.fc-head-container {
    border: 1px solid #c8c8c8;
}

.fc .fc-toolbar .ui-button .ui-icon-circle-triangle-w {
    text-indent: 0;
}

.fc .fc-toolbar .ui-button .ui-icon-circle-triangle-w:before {
    content: '';
}

.fc .fc-toolbar .ui-button .ui-icon-circle-triangle-e {
    text-indent: 0;
}

.fc .fc-toolbar .ui-button .ui-icon-circle-triangle-e:before {
    content: '';
}

.fc .fc-toolbar .ui-button:focus {
    outline: 0 none;
}

.fc .fc-divider {
    background-color: #f4f4f4;
    border: 1px solid #c8c8c8;
}

.ui-fluid .fc .fc-toolbar .ui-button {
    width: auto;
}

.fc .fc-button-primary {
    &:active,
    &:focus {
        box-shadow: rgb(186, 225, 255) 0px 0px 0px 0.2rem !important;
    }
}
