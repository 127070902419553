/*
File: scss
*/

$mini-sidebar-width: 80px;
/*******************
Main sidebar
******************/
#snav {
    // overflow:hidden;

    .navbar-header {
        position: absolute;
        z-index: 999;
        display: flex;
        text-align: center;
        background-color: #e5e8ed;
        height: 70px;
        width: 100%;
        .navbar-brand {
            display: block;
            line-height: 18px;

            align-items: center;
            display: flex;
            line-height: 18px;
            align-items: center;
            color: $sidebar-selected-item;
            margin: auto;
            font:
                600 1.9em/24px 'Open Sans',
                sans-serif;
            .dark-logo {
                display: none;
            }
            b {
                margin-right: 5px;
            }
            img {
                max-width: 220px;
                max-height: 60px;
            }
        }
    }
}

mat-sidenav {
    width: 225px;
    padding-top: 0px;
}
#snav {
    box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);
    .mat-mdc-list-item {
        height: auto;
        padding-right: 0;
        &:hover {
            background: none;
        }
        .mdc-list-item__content {
            display: block;
            padding: 0px 5px;
            &:hover {
                background: none;
                > a {
                    color: none;
                }
            }
        }

        a {
            height: 45px;
            padding: 0 10px;
            margin-bottom: 10px;
            align-items: center;
            display: flex;
            font-size: 15px;
            white-space: nowrap;
            color: $sidebar-text;
            mat-icon:not(.dd-icon) {
                margin-right: 8px;
            }
            .dd-icon {
                font-size: 16px;
                width: 16px;
                transition: 0.2s ease-in;
                margin: 5px 0 5px 5px;
                height: 16px;
            }
        }
        &.active > .mdc-list-item__content a {
            background: $themecolor-alt;
            border-radius: $radius;
            color: $white;

            mat-icon {
                color: $white;
            }
            .dd-icon {
                -webkit-transform: rotate(-180deg);
                transform: rotate(-180deg);
            }
        }
        &.active .sub-item .mdc-list-item__content a.selected {
            background: transparent;
            color: $inverse;
            font-weight: 500;
        }
    }
    .sub-item {
        display: none;
        margin-top: -15px;
        a {
            padding-left: 28px;
            height: 50px;
            margin-bottom: 0px;
        }
    }
    .saperator {
        cursor: text;
        font-size: 13px;
        margin-bottom: 15px;
        margin-top: 25px;
        font-weight: 500;
        white-space: nowrap;
    }
    .selected {
        .sub-item {
            display: block;
        }
    }
}
/*******************
use profile section
******************/

.user-profile {
    position: relative;
    background-size: cover;

    .profile-img {
        width: 50px;
        margin-left: 30px;
        padding: 31px 0;
        border-radius: 100%;
        &::before {
            -webkit-animation: 2.5s blow 0s linear infinite;
            animation: 2.5s blow 0s linear infinite;
            position: absolute;
            content: '';
            width: 50px;
            height: 50px;
            top: 31px;
            margin: 0 auto;
            border-radius: 50%;
            z-index: 0;
        }
        @-webkit-keyframes blow {
            0% {
                box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.1);
                opacity: 1;
                -webkit-transform: scale3d(1, 1, 0.5);
                transform: scale3d(1, 1, 0.5);
            }
            50% {
                box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.1);
                opacity: 1;
                -webkit-transform: scale3d(1, 1, 0.5);
                transform: scale3d(1, 1, 0.5);
            }
            100% {
                box-shadow: 0 0 0 20px rgba(0, 0, 0, 0.1);
                opacity: 0;
                -webkit-transform: scale3d(1, 1, 0.5);
                transform: scale3d(1, 1, 0.5);
            }
        }
        @keyframes blow {
            0% {
                box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.1);
                opacity: 1;
                -webkit-transform: scale3d(1, 1, 0.5);
                transform: scale3d(1, 1, 0.5);
            }
            50% {
                box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.1);
                opacity: 1;
                -webkit-transform: scale3d(1, 1, 0.5);
                transform: scale3d(1, 1, 0.5);
            }
            100% {
                box-shadow: 0 0 0 20px rgba(0, 0, 0, 0.1);
                opacity: 0;
                -webkit-transform: scale3d(1, 1, 0.5);
                transform: scale3d(1, 1, 0.5);
            }
        }
        img {
            width: 100%;
            border-radius: 100%;
        }
    }
    .profile-text {
        padding: 5px 0px;
        position: relative;
        cursor: pointer;
        white-space: nowrap;
        > a {
            color: $white !important;
            width: 100%;
            padding: 6px 30px;
            background: rgba(0, 0, 0, 0.5);
            display: block;
            white-space: nowrap;
            &:after {
                position: absolute;
                right: 20px;
                top: 20px;
            }
        }
    }
}

/*==============================================================
 Small Desktop & above all (1024px)
 ============================================================== */

@media (min-width: 1024px) {
    .minisidebar #snav {
        .mat-drawer-backdrop {
            display: none;
        }
        &.mat-sidenav ~ .mat-drawer-content {
            margin-left: $mini-sidebar-width;
        }
        &.mat-sidenav {
            -webkit-transition:
                width 0.2s cubic-bezier(0.25, 0.8, 0.25, 1),
                transform 0.2s cubic-bezier(0.25, 0.8, 0.25, 1) !important;
            transition:
                width 0.2s cubic-bezier(0.25, 0.8, 0.25, 1),
                transform 0.2s cubic-bezier(0.25, 0.8, 0.25, 1) !important;
        }
        &.mat-sidenav:not(:hover) {
            width: $mini-sidebar-width;
            .sub-menu,
            .dd-icon,
            .selected .sub-item {
                display: none;
                visibility: hidden;
            }
            .user-profile {
                .profile-img {
                    margin-left: 15px;
                }
                .profile-text {
                    visibility: hidden;
                }
            }
            .saperator span {
                overflow: hidden;
                text-overflow: ellipsis;
                width: 40px;
                display: block;
            }
            .mat-mdc-list-item > .mdc-list-item__content a {
                padding-right: 0;
                padding-left: 0;
                text-align: center;
            }
            .mat-mdc-list-item > .mdc-list-item__content a > span:not(.menu-badge),
            .mat-mdc-list-item > .mdc-list-item__content a > .menu-caret {
                display: none;
            }
            .mat-mdc-list-item > .mdc-list-item__content a > span.menu-badge {
                position: absolute;
                top: 10px;
                right: 16px;
            }
            .mat-mdc-list-item > .mdc-list-item__content a > .material-icons {
                width: $mini-sidebar-width !important;
                padding: 0;
                margin: 0;
            }
        }
    }
    [dir='rtl'] {
        app-sidebar {
            display: block;
            overflow: hidden;
        }
        .minisidebar #snav.mat-sidenav ~ .mat-drawer-content {
            margin-left: 0px !important;
            margin-right: $mini-sidebar-width !important;
        }
    }
}

.sidebar-closed #snav {
    &.mat-sidenav ~ .mat-drawer-content {
        margin-left: 0px !important;
    }
}
#snav .mat-mdc-list-item.active > .mdc-list-item__content a {
    background: $sidebar-selected-item;
    border-radius: 4px;
    color: #ffffff;
}

mat-nav-list#matnavList {
    padding-top: 70px;
}

@media (min-width: 767px) and (max-width: 768px) {
    mat-nav-list#matnavList {
        margin-top: 0px;
    }
}
.material-icons {
    font-size: 18px;
}

#snav .mat-mdc-list-item a {
    font-size: 16px;
    margin-bottom: 0px;
    text-transform: capitalize;
}

mat-sidenav mat-nav-list#matnavList {
    margin-bottom: 10px;
}

// Form field code
/*

 .page-content .mat-form-field-label {
   font-size: 14px;
   transform: translateY(-7.58px);
}

.mat-focused .mat-form-field-label{
  transform: translateY(-22px) scale(0.8)!important;
}

.mat-form-field {
  font-size: 10px;
}

.page-content .mat-input-element {
  font-size: 14px;
}

::ng-deep .mat-input-wrapper{
  font-size: 12px;
}
*/
