/*
File: scss
*/
/**
 * Table Of Content
 *
 * 	1. Globals
 *	2. Headers
 *	3. Navigations
 *	4. Banners
 *	5. Footers
 *	6. app
 *	7. Widgets
 *	8. Custom Templates
 *  9. Custom Theme
 */

@import 'variable';
@import 'theme';

@import 'spinner';
@import 'app';
@import 'animate';
@import 'header';
@import 'pages';
@import 'sidebar';
@import 'responsive';
@import 'common';
@import 'bootstrap-missed';
@import 'fullcalendar';
@import 'taskboard';
@import 'material/index';

@import '@ng-select/ng-select/themes/default.theme.css';
@font-face {
    font-family: 'icomoon';
    src: url('../icon/icofont/fonts2/icomoon.eot?pyh79');
    src:
        url('../icon/icofont/fonts2/icomoon.eot?pyh79#iefix') format('embedded-opentype'),
        url('../icon/icofont/fonts2/icomoon.ttf?pyh79') format('truetype'),
        url('../icon/icofont/fonts2/icomoon.woff?pyh79') format('woff'),
        url('../icon/icofont/fonts2/icomoon.svg?pyh79#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^='icon-'],
[class*=' icon-'] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'Material Icons';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
