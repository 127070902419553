@use '@angular/material' as mat;
@include mat.core();

$red-common: #ec2121;
$green-common: #4caf50;
$blue-common: #03a9f4;
$violet-common: #605ca8;
$purple-common: #9c27b0;
$indigo-common: #3f51b5;

$sideBar-dark-color: #000000;
$sideBar-light-color: #e1e2e3;

$sidebar-selected-item: #ec2121;
$sidebar-unselect-item-theme-color: black;
$mat-red: mat.m2-define-palette(mat.$m2-red-palette);
$my-theme-primary: mat.m2-define-palette($mat-red);
$my-theme-accent: mat.m2-define-palette(mat.$m2-green-palette, A200, A100, A400);
$my-theme-warn: mat.m2-define-palette($mat-red);

$my-red-theme-dark: mat.m2-define-dark-theme(
    (
        color: (
            primary: $my-theme-primary,
            accent: $my-theme-accent,
            warn: $my-theme-warn,
        ),
        typography: mat.m2-define-typography-config(),
        density: 0,
    )
);
$my-red-theme-light: mat.m2-define-light-theme(
    (
        color: (
            primary: $my-theme-primary,
            accent: $my-theme-accent,
            warn: $my-theme-warn,
        ),
        typography: mat.m2-define-typography-config(),
        density: 0,
    )
);

$my-green-theme-dark: mat.m2-define-dark-theme(
    (
        color: (
            primary: mat.m2-define-palette(mat.$m2-green-palette),
            accent: mat.m2-define-palette(mat.$m2-yellow-palette, A200, A100, A400),
            warn: mat.m2-define-palette($mat-red),
        ),
        typography: mat.m2-define-typography-config(),
        density: 0,
    )
);
$my-green-theme-light: mat.m2-define-light-theme(
    (
        color: (
            primary: mat.m2-define-palette(mat.$m2-green-palette),
            accent: mat.m2-define-palette(mat.$m2-yellow-palette, A200, A100, A400),
            warn: mat.m2-define-palette($mat-red),
        ),
        typography: mat.m2-define-typography-config(),
        density: 0,
    )
);

$my-blue-theme-dark: mat.m2-define-dark-theme(
    (
        color: (
            primary: mat.m2-define-palette(mat.$m2-light-blue-palette),
            accent: mat.m2-define-palette(mat.$m2-deep-purple-palette, A200, A100, A400),
            warn: mat.m2-define-palette($mat-red),
        ),
        typography: mat.m2-define-typography-config(),
        density: 0,
    )
);
$my-blue-theme-light: mat.m2-define-light-theme(
    (
        color: (
            primary: mat.m2-define-palette(mat.$m2-light-blue-palette),
            accent: mat.m2-define-palette(mat.$m2-deep-purple-palette, A200, A100, A400),
            warn: mat.m2-define-palette($mat-red),
        ),
        typography: mat.m2-define-typography-config(),
        density: 0,
    )
);

$my-purple-theme-dark: mat.m2-define-dark-theme(
    (
        color: (
            primary: mat.m2-define-palette(mat.$m2-purple-palette),
            accent: mat.m2-define-palette(mat.$m2-deep-orange-palette, A200, A100, A400),
            warn: mat.m2-define-palette($mat-red),
        ),
        typography: mat.m2-define-typography-config(),
        density: 0,
    )
);
$my-purple-theme-light: mat.m2-define-light-theme(
    (
        color: (
            primary: mat.m2-define-palette(mat.$m2-purple-palette),
            accent: mat.m2-define-palette(mat.$m2-deep-orange-palette, A200, A100, A400),
            warn: mat.m2-define-palette($mat-red),
        ),
        typography: mat.m2-define-typography-config(),
        density: 0,
    )
);

$my-indigo-theme-dark: mat.m2-define-dark-theme(
    (
        color: (
            primary: mat.m2-define-palette(mat.$m2-indigo-palette),
            accent: mat.m2-define-palette(mat.$m2-amber-palette, A200, A100, A400),
            warn: mat.m2-define-palette($mat-red),
        ),
        typography: mat.m2-define-typography-config(),
        density: 0,
    )
);
$my-indigo-theme-light: mat.m2-define-light-theme(
    (
        color: (
            primary: mat.m2-define-palette(mat.$m2-indigo-palette),
            accent: mat.m2-define-palette(mat.$m2-amber-palette, A200, A100, A400),
            warn: mat.m2-define-palette($mat-red),
        ),
        typography: mat.m2-define-typography-config(),
        density: 0,
    )
);

$general-typography: mat.m2-define-typography-config(
    $body-2: mat.m2-define-typography-level(16px, 34px, 800),
    $font-family: '"Open Sans", sans-serif',
);

@include mat.core-typography($general-typography);

.red-dark {
    $sidebar-selected-item: $red-common;
    --sidebar-selected-item: $red-common;
    $sidebar-unselect-item-theme-color: $sideBar-light-color;
    @import 'common';
    @import 'mailbox';
    @import 'pages';
    @import 'sidebar';
    @include mat.all-component-colors($my-red-theme-dark);

    .page-content {
        @include mat.core-color($my-red-theme-light);
    }

    .customize-filter-wrapper .p-highlight {
        background: $sidebar-selected-item;
    }

    .hr-class {
        border-top: 2px solid $red-common;
        margin-top: 5px;
        margin-bottom: 0;
        opacity: 1;
    }
    .mat-mdc-tab-header,
    .show-border.mat-mdc-tab-group .mat-mdc-tab-header {
        border-bottom: 2px solid $red-common !important;
        margin-top: 0px;
        margin-bottom: 0;
    }
    .timer-border {
        border: 1px solid $red-common;
    }
    .mat-mdc-cell {
        color: black;
    }
    .fa.fa-file-pdf-o,
    .fa.fa-file-excel-o,
    .fa.fa-filter {
        color: #212121;
    }
    .coa-expand-collapse-btns {
        color: $red-common !important;
    }

    #snav #matnavList .mat-mdc-list-item.menu-list-item > .mdc-list-item__content a {
        color: #ffffff;
    }
    // #snav .mat-drawer-inner-container .navbar-header {background-color: #424242;}

    @include mat.core-typography($general-typography);
    font-size: 14px;
}

.red-light {
    $sidebar-selected-item: $red-common;
    --sidebar-selected-item: $red-common;
    $sidebar-unselect-item-theme-color: $sideBar-dark-color;
    @import 'common';
    @import 'mailbox';
    @import 'pages';
    @import 'sidebar';
    @include mat.all-component-colors($my-red-theme-light);
    font-size: 14px;

    .customize-filter-wrapper .p-highlight {
        background: $sidebar-selected-item;
    }

    .hr-class {
        border-top: 2px solid $red-common;
        margin-top: 5px;
        margin-bottom: 0;
        opacity: 1;
    }
    .mat-mdc-tab-header,
    .show-border.mat-mdc-tab-group .mat-mdc-tab-header {
        border-bottom: 2px solid $red-common !important;
        margin-top: 0px;
        margin-bottom: 0;
    }
    .timer-border {
        border: 1px solid $red-common;
    }
    .coa-expand-collapse-btns {
        color: $red-common !important;
    }
    @include mat.core-typography($general-typography);
}

.blue-dark {
    $sidebar-selected-item: $blue-common;
    --sidebar-selected-item: $blue-common;
    $sidebar-unselect-item-theme-color: $sideBar-light-color;
    @import 'common';
    @import 'mailbox';
    @import 'pages';
    @import 'sidebar';
    @include mat.all-component-colors($my-blue-theme-dark);

    .page-content {
        @include mat.core-color($my-blue-theme-light);
    }

    .customize-filter-wrapper .p-highlight {
        background: $sidebar-selected-item;
    }

    font-size: 14px;
    .hr-class {
        border-top: 2px solid $blue-common;
        margin-top: 5px;
        margin-bottom: 0;
        opacity: 1;
    }
    .mat-mdc-tab-header,
    .show-border.mat-mdc-tab-group .mat-mdc-tab-header {
        border-bottom: 2px solid $blue-common !important;
        margin-top: 0px;
        margin-bottom: 0;
    }
    .timer-border {
        border: 1px solid $blue-common;
    }
    .mat-mdc-cell {
        color: black;
    }
    .fa.fa-file-pdf-o,
    .fa.fa-file-excel-o,
    .fa.fa-filter {
        color: #212121;
    }
    .coa-expand-collapse-btns {
        color: $blue-common !important;
    }

    #snav #matnavList .mat-mdc-list-item.menu-list-item > .mdc-list-item__content a {
        color: #ffffff;
    }
    // #snav .mat-drawer-inner-container .navbar-header {background-color: #424242;}

    @include mat.core-typography($general-typography);
}

.blue-light {
    $sidebar-selected-item: $blue-common;
    --sidebar-selected-item: $blue-common;
    $sidebar-unselect-item-theme-color: $sideBar-dark-color;
    @import 'common';
    @import 'mailbox';
    @import 'pages';
    @import 'sidebar';
    @include mat.all-component-colors($my-blue-theme-light);
    font-size: 14px;

    .customize-filter-wrapper .p-highlight {
        background: $sidebar-selected-item;
    }

    .hr-class {
        border-top: 2px solid $blue-common;
        margin-top: 5px;
        margin-bottom: 0;
        opacity: 1;
    }
    .mat-mdc-tab-header,
    .show-border.mat-mdc-tab-group .mat-mdc-tab-header {
        border-bottom: 2px solid $blue-common !important;
        margin-top: 0px;
        margin-bottom: 0;
    }
    .timer-border {
        border: 1px solid $blue-common;
    }
    .coa-expand-collapse-btns {
        color: $blue-common !important;
    }
    @include mat.core-typography($general-typography);
}

.green-dark {
    $sidebar-unselect-item-theme-color: $sideBar-light-color;
    $sidebar-selected-item: $green-common;
    --sidebar-selected-item: $green-common;
    @import 'common';
    @import 'mailbox';
    @import 'pages';
    @import 'sidebar';
    @include mat.all-component-colors($my-green-theme-dark);

    .page-content {
        @include mat.core-color($my-green-theme-light);
    }

    .customize-filter-wrapper .p-highlight {
        background: $sidebar-selected-item;
    }

    font-size: 14px;
    .hr-class {
        border-top: 2px solid $green-common;
        margin-top: 5px;
        margin-bottom: 0;
        opacity: 1;
    }
    .mat-mdc-tab-header,
    .show-border.mat-mdc-tab-group .mat-mdc-tab-header {
        border-bottom: 2px solid $green-common !important;
        margin-top: 0px;
        margin-bottom: 0;
    }
    .timer-border {
        border: 1px solid $green-common;
    }
    .mat-mdc-cell {
        color: black;
    }
    .fa.fa-file-pdf-o,
    .fa.fa-file-excel-o,
    .fa.fa-filter {
        color: #212121;
    }
    .coa-expand-collapse-btns {
        color: $green-common !important;
    }

    #snav #matnavList .mat-mdc-list-item.menu-list-item > .mdc-list-item__content a {
        color: #ffffff;
    }
    // #snav .mat-drawer-inner-container .navbar-header {background-color: #424242;}

    @include mat.core-typography($general-typography);
}

.green-light {
    $sidebar-unselect-item-theme-color: $sideBar-dark-color;
    $sidebar-selected-item: $green-common;
    --sidebar-selected-item: $green-common;
    @import 'common';
    @import 'mailbox';
    @import 'pages';
    @import 'sidebar';
    @include mat.all-component-colors($my-green-theme-light);
    font-size: 14px;

    .customize-filter-wrapper .p-highlight {
        background: $sidebar-selected-item;
    }

    .hr-class {
        border-top: 2px solid $green-common;
        margin-top: 5px;
        margin-bottom: 0;
        opacity: 1;
    }
    .mat-mdc-tab-header,
    .show-border.mat-mdc-tab-group .mat-mdc-tab-header {
        border-bottom: 2px solid $green-common !important;
        margin-top: 0px;
        margin-bottom: 0;
    }
    .timer-border {
        border: 1px solid $green-common;
    }
    .coa-expand-collapse-btns {
        color: $green-common !important;
    }
    @include mat.core-typography($general-typography);
}

.purple-dark {
    $sidebar-selected-item: $purple-common;
    --sidebar-selected-item: $purple-common;
    $sidebar-unselect-item-theme-color: $sideBar-light-color;
    @import 'common';
    @import 'mailbox';
    @import 'pages';
    @import 'sidebar';
    @include mat.all-component-colors($my-purple-theme-dark);

    .page-content {
        @include mat.core-color($my-purple-theme-light);
    }

    .customize-filter-wrapper .p-highlight {
        background: $sidebar-selected-item;
    }

    .hr-class {
        border-top: 2px solid $purple-common;
        margin-top: 5px;
        margin-bottom: 0;
        opacity: 1;
    }
    .timer-border {
        border: 1px solid $purple-common;
    }
    .mat-mdc-tab-header,
    .show-border.mat-mdc-tab-group .mat-mdc-tab-header {
        border-bottom: 2px solid $purple-common !important;
        margin-top: 0px;
        margin-bottom: 0;
    }
    .mat-mdc-cell {
        color: black;
    }
    .fa.fa-file-pdf-o,
    .fa.fa-file-excel-o,
    i.fa.fa-filter {
        color: #212121;
    }
    .coa-expand-collapse-btns {
        color: $purple-common !important;
    }
    font-size: 14px;

    #snav #matnavList .mat-mdc-list-item.menu-list-item > .mdc-list-item__content a {
        color: #ffffff;
    }
    #snav #matnavList .mat-mdc-list-item.menu-list-item.active > .mdc-list-item__content a {
        background: #ffffff;
    }
    // #snav .mat-drawer-inner-container .navbar-header {background-color: #424242;}

    @include mat.core-typography($general-typography);
}

.purple-light {
    $sidebar-unselect-item-theme-color: $sideBar-dark-color;
    $sidebar-selected-item: $purple-common;
    --sidebar-selected-item: $purple-common;
    @import 'common';
    @import 'mailbox';
    @import 'pages';
    @import 'sidebar';
    @include mat.all-component-colors($my-purple-theme-light);

    .customize-filter-wrapper .p-highlight {
        background: $sidebar-selected-item;
    }

    font-size: 14px;
    .hr-class {
        border-top: 2px solid $purple-common;
        margin-top: 5px;
        margin-bottom: 0;
        opacity: 1;
    }
    .mat-mdc-tab-header,
    .show-border.mat-mdc-tab-group .mat-mdc-tab-header {
        border-bottom: 2px solid $purple-common !important;
        margin-top: 0px;
        margin-bottom: 0;
    }
    .timer-border {
        border: 1px solid $purple-common;
    }
    .coa-expand-collapse-btns {
        color: $purple-common !important;
    }
    @include mat.core-typography($general-typography);
}

.indigo-dark {
    $sidebar-selected-item: $indigo-common;
    --sidebar-selected-item: $indigo-common;
    $sidebar-unselect-item-theme-color: $sideBar-light-color;
    @import 'common';
    @import 'mailbox';
    @import 'pages';
    @import 'sidebar';
    @include mat.all-component-colors($my-indigo-theme-dark);

    .page-content {
        @include mat.core-color($my-indigo-theme-light);
    }

    .customize-filter-wrapper .p-highlight {
        background: $sidebar-selected-item;
    }

    .mat-mdc-cell {
        color: black;
    }
    .fa.fa-file-pdf-o,
    .fa.fa-file-excel-o,
    .fa.fa-filter {
        font-size: 18px;
        // font-weight: 500;
        padding-bottom: 5px;
        color: #212121;
    }

    .hr-class {
        border-top: 2px solid $indigo-common;
        margin-top: 5px;
        margin-bottom: 0;
        opacity: 1;
    }
    .mat-mdc-tab-header,
    .show-border.mat-mdc-tab-group .mat-mdc-tab-header {
        border-bottom: 2px solid $indigo-common !important;
        margin-top: 0px;
        margin-bottom: 0;
    }
    .timer-border {
        border: 1px solid $indigo-common;
    }
    .coa-expand-collapse-btns {
        color: $indigo-common !important;
    }
    font-size: 14px;

    #snav #matnavList .mat-mdc-list-item.menu-list-item > .mdc-list-item__content a {
        color: #ffffff;
    }
    #snav #matnavList .mat-mdc-list-item.menu-list-item.active > .mdc-list-item__content a {
        background: #ffffff;
    }
    // #snav .mat-drawer-inner-container .navbar-header {background-color: #424242;}

    @include mat.core-typography($general-typography);
}

.indigo-light {
    $sidebar-selected-item: $indigo-common;
    --sidebar-selected-item: $indigo-common;
    $sidebar-unselect-item-theme-color: $sideBar-dark-color;
    @import 'common';
    @import 'mailbox';
    @import 'pages';
    @import 'sidebar';
    @include mat.all-component-colors($my-indigo-theme-light);

    .customize-filter-wrapper .p-highlight {
        background: $sidebar-selected-item;
    }

    .hr-class {
        border-top: 2px solid $indigo-common;
        margin-top: 0px;
        margin-bottom: 0;
        opacity: 1;
    }
    .mat-mdc-tab-header,
    .show-border.mat-mdc-tab-group .mat-mdc-tab-header {
        border-bottom: 2px solid $indigo-common !important;
        margin-top: 0px;
        margin-bottom: 0;
    }
    .timer-border {
        border: 1px solid $indigo-common;
    }
    .coa-expand-collapse-btns {
        color: $indigo-common !important;
    }
    font-size: 14px;
    @include mat.core-typography($general-typography);
}
