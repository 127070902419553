.gmailTheme {
    .mat-mdc-button {
        background-color: none;
    }

    .activeUser {
        border-left: 3px solid $sidebar-selected-item;
    }
    .selectedName {
        color: $sidebar-selected-item !important ;
    }

    .mail-folder-list {
        .mat-mdc-list-item.selected {
            color: $sidebar-selected-item;
            font-weight: 600;
            .selected:before {
                content: '';
                position: absolute;
                top: 0px;
                left: 0;
                // height: 45px;
                height: 4rem;
                border-left: 3px solid $sidebar-selected-item;
                width: 100%;
            }
            .selected:after {
                content: '';
                position: absolute;
                top: 0px;
                left: 0;
                height: 45px;
                background-color: $sidebar-selected-item;
                opacity: 0.02;
                width: 100%;
            }
            svg {
                .a {
                    fill: $sidebar-selected-item;
                }
            }
        }
        .mat-mdc-list-item {
            // height: 45px;
            height: 4rem;
            font-size: 1.4rem;
            font-weight: 400;
            svg {
                .a {
                    fill: #a5a5a5;
                }
            }
        }
    }
    .AllMailCount {
        color: $sidebar-selected-item;
        padding: 5px 8px;
        display: inline-block;
        min-width: 10px;
        font-size: 12px;
        font-weight: 700;
        line-height: 1;
        text-align: center;
        white-space: nowrap;
        vertical-align: middle;
        border-radius: 10px;
        position: relative;
        &::before {
            content: '';
            position: absolute;
            left: 0px;
            top: 0px;
            opacity: 0.08;
            background-color: $sidebar-selected-item;
            width: 100%;
            height: 100%;
            border-radius: 10px;
        }
    }
    .mailcardwrap.selected,
    .mailcardwrap.activeSelectedMail {
        background: #ffffff !important; //#F9F9F9 !important;
        border-left: 2px solid $sidebar-selected-item !important;
    }

    .activeSelectedMail:before {
        content: '';
        position: absolute;
        top: 0px;
        left: 0;
        height: 100%;
        border-left: 3px solid $sidebar-selected-item;
        width: 100%;
    }
    // .activeSelectedMail:after {
    //   content: '';
    //   position: absolute;
    //   top: 0px;
    //   left: 0;
    //   height: 100%;
    //   background-color: $sidebar-selected-item;
    //   opacity: 0.06;
    //   width: 100%;
    //   z-index:0;
    // }

    .mcalText {
        color: $sidebar-selected-item;
    }
    .mailboxDetailPanel {
        .emptyMailDetailSection {
            font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 600;
            font-size: 1.4rem;
            height: 100%;
        }
        .mldHeader {
            // min-height: 11rem;
            /*max-height: 11rem;*/
            background: $sidebar-selected-item;
            color: #fff;
            border-bottom: 1px solid #eee;
            padding: 1rem 0;

            .optIcon:hover {
                background: #fff !important;
                .mat-icon {
                    color: $sidebar-selected-item !important;
                }
            }
        }
    }
    .mailbox-sidebar .sidenav_container_outer .mailboxListPanel .contentWrap .mailboxCustomPagination .pagination a.active {
        background: $sidebar-selected-item !important;
        color: #fff !important;
    }
    .customAttachCount {
        color: $sidebar-selected-item !important;
    }
    .mailWrap .mailboxInnerCountDots {
        background: $sidebar-selected-item !important;
    }
    .initial-letter-as-img {
        background: $sidebar-selected-item !important;
        color: #fff !important;
        font-weight: normal !important;
        font-size: 1.4rem !important;
    }
    .search-bar-btns {
        .mailbox-search-bar-btn {
            background: $sidebar-selected-item !important;
            color: #fff !important;
        }
    }
    button.mailbox-search-bar-btn,
    .search-bar-btn {
        background: $sidebar-selected-item !important;
        color: #fff !important;
    }
}
.search-bar-btns {
    .mailbox-search-bar-btn {
        background-color: $sidebar-selected-item !important;
        color: #fff !important;
    }
    button.mailbox-search-bar-btn {
        background-color: $sidebar-selected-item !important;
        color: #fff !important;
    }
}
.outlookTheme {
    .mat-mdc-button {
        background-color: none;
    }

    .mail-folder-list {
        .mat-mdc-list-item.selected {
            color: $sidebar-selected-item;
        }
        .mat-mdc-list-item {
            height: 25px;
            font-size: 13px;
            font-weight: 400;
        }
    }
}

.compose-layout .header-layout {
    background-color: $sidebar-selected-item;
}

.mat-mdc-list-item .status {
    width: 30px;
    height: 24px;
    display: block;
}

#mail-content {
    position: relative;
    #mail-detail-option-button {
        position: absolute;
        top: 0;
        left: 30px;
    }
}
app-mail-list-options {
    .mat-mdc-paginator-range-label {
        margin: 0px !important;
    }
}
@media (max-width: 967px) {
    .gmailTheme {
        .mat-drawer-content {
            display: inherit !important;
        }
    }
}

/*******************
 Compose MailBox
*******************/

.drafts_box {
    ._compose_box {
        height: calc(100% - 42px) !important;
        background-color: #ffffff;
    }
    .emptyMailDetailSection,
    ._detail {
        height: calc(100% - 42px) !important;
    }
}

.bottom_draft_wrapper {
    position: relative;
    width: 100%;
    border-top: 1px solid #efefef;
    padding: 0.5rem 1.5rem 0;
    height: 42px;
    background-color: #ffffff;
    .drafts_active_li_Wrapper {
        display: flex;
        margin-top: 0.5rem;
        flex: 0 0 auto;
        flex-flow: row nowrap;
        .drafts_li {
            font-size: 1.25rem;
            color: #333;
            padding: 0.67rem 1rem;
            margin-right: 0.5rem;
            border-radius: 10px 10px 0px 0px;
            line-height: 1.4;
            background-color: #f8f8f8;
            display: flex;
            align-items: center;
            cursor: pointer;
            flex: 0 0 auto;
            width: 200px;
            min-width: 80px;
            max-width: 200px;
            text-overflow: ellipsis;
            white-space: nowrap;
            flex-shrink: 1;
            &._active {
                color: $sidebar-selected-item;
                background-color: rgba($sidebar-selected-item, 0.1);
            }
            .fa-edit {
                font-size: 1.35rem;
                margin-right: 0.45rem;
            }
            ._close {
                padding: 0.25rem 0.5rem;
                font-size: 1.45rem;
                margin-left: 0.45rem;
                cursor: pointer;
            }
            .fa-chevron-down {
                padding: 0.25rem;
                font-size: 1.35rem;
                margin-left: 0.25rem;
            }
            strong {
                margin-right: 0.35rem;
            }
            ._subject_text {
                flex-grow: 1;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            ._more_text {
                flex-grow: 1;
            }
        }
        &::-webkit-scrollbar {
            display: block;
            width: 0.5em;
        }
        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        }
        &::-webkit-scrollbar-thumb {
            background-color: #a9a9a9;
            border-radius: 4px;
        }
    }
}

app-compose-box {
    .compose_email {
        padding: 1.125rem 0 1.125rem 0rem;
        background-color: $white;
        height: 100%;
        .compose_title {
            padding: 0 1.25rem;
            position: relative;
            margin-bottom: 1rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            h4 {
                margin: 0;
                color: #333333;
                font-weight: 600;
                font-size: 1.5rem;
                display: flex;
                flex: 0 0 auto;
                max-width: calc(100% - 10rem);
                text-overflow: ellipsis;
                white-space: nowrap;
                flex-wrap: nowrap;
                overflow: hidden;
                // padding-right: 5rem;
                i {
                    color: $sidebar-selected-item;
                    background: rgba($sidebar-selected-item, 0.1);
                    font-size: 1.25rem;
                    font-size: 1.25rem;
                    width: 2rem;
                    height: 2rem;
                    text-align: center;
                    display: inline-block;
                    line-height: 2rem;
                    border-radius: 6px;
                    vertical-align: middle;
                    cursor: pointer;
                }
            }
            .btns_wrapper {
                display: flex;
                flex: 0 0 9.25rem;
                max-width: 9.25rem;
                > button {
                    width: 2.75rem;
                    height: 2.75rem;
                    line-height: 2.75rem;
                    cursor: pointer;
                    background-color: rgba($sidebar-selected-item, 0.1);
                    color: $sidebar-selected-item;
                    border-radius: 50%;
                    margin-right: 0.5rem;
                    border-width: 0;
                    transition: all 0.4s;
                    &:hover {
                        background-color: rgba($sidebar-selected-item, 1);
                        color: $white;
                    }
                    &:last-child {
                        margin-right: 0;
                    }
                    i {
                        font-size: 1.5rem;
                        font-weight: 600;
                    }
                    .icon-full-screen {
                        font-size: 1.25rem;
                        font-weight: 600;
                    }
                }
            }
        }
        .form_group {
            margin-bottom: 0.75rem;
            background-color: $white;
            display: flex;
            align-items: center;
            border-radius: 10px;
            padding: 0.125em 0;
            background-color: $white;
            border: 1px solid #d6d7d9;
            .input_addon {
                display: inline-flex;
                flex: 0 0 auto;
                padding: 0.25em 0.875em;
                position: relative;
                font-size: 1.25rem;
                color: rgba(#333333, 0.8);
            }
            .mail-field {
                width: 100%;
                .ng2-tag-input {
                    min-height: 28px;
                    line-height: 28px;
                    border-bottom: 0;
                    padding: 0;
                }
            }
            .cc_btns {
                > .ccBcc {
                    color: $sidebar_selected-item;
                    font-size: 12px;
                    font-weight: 600;
                    padding: 0.35rem 0.5rem;
                    margin: 0 0.25rem;
                    border-radius: 6px;
                    cursor: pointer;
                    position: relative;
                    display: inline-block;
                    &::before {
                        content: '';
                        position: absolute;
                        left: 0px;
                        top: 0px;
                        opacity: 0.08;
                        background-color: $sidebar-selected-item;
                        width: 100%;
                        height: 100%;
                        border-radius: 5px;
                    }
                }
            }
            ng-select.custom-select {
                width: calc(100% - 84px);
                .ng-select-container {
                    min-height: 28px;
                    height: 28px !important;
                    border-radius: 10px 10px 10px 10px;
                    border: 0px solid #ccc;
                    &:hover {
                        box-shadow: none;
                    }
                    .ng-value-label {
                        color: $sidebar-selected-item;
                        font-size: 13px;
                        font-weight: 400 !important;
                    }
                    .ng-arrow {
                        height: 0.75rem;
                        width: 0.75rem;
                        border-color: transparent transparent #999 #999;
                        border-width: 0 0 0.22rem 0.22rem;
                        transform: rotate(-45deg);
                        top: -2px;
                    }
                }
                &.ng-select-opened {
                    .ng-arrow {
                        transform: rotate(135deg);
                    }
                }
            }
            .ng-select-focused:not(.ng-select-opened) > .ng-select-container {
                border: 0px solid #ccc;
            }
            input.mail_subject {
                border: 0px;
                width: 100%;
                min-height: 28px;
                padding: 0.25rem 0.5em;
                font-size: 13px;
                border-radius: 0 10px 10px 0;
                color: $sidebar-selected-item;
            }
            &.mb-0 {
                margin-bottom: 0;
            }
            tag {
                padding: 0.5rem 3rem 0.5rem 0.75rem;
                height: 26px;
                line-height: 16px;
                background: rgba($sidebar-selected-item, 0.1);
                color: $sidebar-selected-item;
                letter-spacing: 0;
                font-size: 12px;
                font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
                margin: 0.125rem 0.125rem;
                .ng2-tag__remove-button {
                    position: absolute;
                    width: 26px;
                    height: 26px;
                    display: inline-block;
                    top: 0;
                    background-color: rgba($sidebar-selected-item, 0.25);
                    border-radius: 14px;
                    right: 0;
                    text-align: center;
                    line-height: 26px;
                    font-size: 1.75rem;
                }
                &:hover {
                    box-shadow: none !important;
                    background: rgba($sidebar-selected-item, 0.1) !important;
                    color: $sidebar-selected-item !important;
                }
                &:not(.readonly):not(.tag--editing):active {
                    background: rgba($sidebar-selected-item, 0.1);
                    color: $sidebar-selected-item;
                    box-shadow: none;
                }
            }
            .ng2-tag-input__text-input {
                font-size: 13px;
                font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
                color: $sidebar-selected-item;
            }
            .mat-form-field-appearance-outline .mat-mdc-text-field-wrapper {
                margin: 0em 0;
            }
            .mat-form-field-appearance-outline .mat-mdc-form-field-infix {
                padding: 0.25em 0 0.25em 0;
            }
            /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
            .mat-form-field-appearance-outline .mat-form-field-outline {
                color: rgba(0, 0, 0, 0);
            }
            .mat-mdc-form-field-infix {
                padding: 0.5em 0;
            }
        }
        .compose_body {
            .mail_content {
                position: relative;
                &::before,
                &::after {
                    content: '';
                    width: 1px;
                    height: 20px;
                    border-right: 1px solid #d6d7d9;
                    position: absolute;
                    top: -10px;
                    left: 0;
                }
                &::after {
                    left: auto;
                    right: 0;
                }
            }
        }
        .attachments_list_wrap {
            margin-top: 0.5rem;
            h4 {
                font-size: 1.25rem;
            }
            .attachments_li {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                ._li {
                    display: flex;
                    align-items: center;
                    background-color: #efefef;
                    color: #333333;
                    padding: 0.5rem;
                    font-size: 1.25rem;
                    margin-right: 0.5rem;
                    border-radius: 6px;
                    line-height: 1;
                    .attachFile {
                        padding-right: 0.75rem;
                        color: $sidebar-selected-item;
                    }
                    i {
                        cursor: pointer;
                    }
                }
            }
        }
        .compose_buttons {
            margin-top: 1rem;
            display: flex;
            button {
                border-radius: 20px;
                .btn_divider {
                    display: inline-block;
                    width: 1px;
                    height: 1em;
                    border-right: 1px solid $white;
                    margin: 0 0 0 0.25rem;
                }
            }
            .mr-1 {
                margin-right: 0.25em;
            }
            .extra_features {
                display: flex;
                justify-content: space-between;
                flex-grow: 1;
                .attachments_wrap {
                    display: flex;
                    align-items: center;
                    padding: 0 0.5rem;
                    position: relative;
                    button {
                        border-radius: 6px;
                        border-width: 0;
                        background-color: transparent;
                        color: #8d8d8d;
                        font-size: 1.5rem;
                        @media screen and (max-width: 1366px) {
                            padding: 0;
                        }
                        &:hover {
                            border-radius: 6px;
                            background-color: rgba($sidebar-selected-item, 0.1);
                            > span {
                                color: $sidebar-selected-item;
                            }
                        }
                    }
                    &:after {
                        content: '';
                        width: 1px;
                        height: 50%;
                        position: absolute;
                        top: 50%;
                        right: 0;
                        transform: translateY(-50%);
                        border-right: 1px solid #d6d7d9;
                    }
                }
                .template_feature {
                    display: flex;
                    align-items: center;
                    position: relative;
                    > div {
                        margin: 0 0.5rem;
                        color: #8d8d8d;
                        font-size: 13px;
                        padding: 0.125rem 0.25rem;
                        cursor: pointer;
                        @media screen and (max-width: 1366px) {
                            font-size: 12px;
                        }
                    }
                    mat-checkbox {
                        .mdc-checkbox {
                            margin-right: 4px;
                            width: 14px;
                            height: 14px;
                        }
                        label {
                            font-weight: 500;
                            font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
                            margin-bottom: 0;
                        }
                    }
                    .follow_up {
                        position: relative;
                        padding-right: 1rem;
                        &:after {
                            content: '';
                            width: 1px;
                            height: 50%;
                            position: absolute;
                            top: 50%;
                            right: 0;
                            transform: translateY(-50%);
                            border-right: 1px solid #d6d7d9;
                        }
                        .hidden_xs {
                            @media screen and (max-width: 1279px) {
                                display: none;
                            }
                        }
                    }
                }
                .features_editor {
                    display: flex;
                    margin: 0 0.5em;
                    padding-right: 0.5rem;
                    align-items: center;
                    position: relative;
                    &:after {
                        width: 1px;
                        height: 50%;
                        position: absolute;
                        top: 50%;
                        right: 0;
                        transform: translateY(-50%);
                        border-right: 1px solid #d6d7d9;
                    }
                    > div {
                        padding: 0.25rem 0.5rem;
                        cursor: pointer;
                        > span {
                            font-size: 1.5rem;
                            color: #8d8d8d;
                        }
                        &:hover,
                        &.active {
                            border-radius: 6px;
                            background-color: rgba($sidebar-selected-item, 0.1);
                            > span {
                                color: $sidebar-selected-item;
                            }
                        }
                    }
                }
                .discard_compose {
                    display: flex;
                    align-items: center;
                    margin: 0 0.5em 0 auto;
                    > div {
                        padding: 0.125em 0.5em;
                        cursor: pointer;
                        > span {
                            font-size: 1.5rem;
                            color: #8d8d8d;
                        }
                        &:hover {
                            border-radius: 6px;
                            background-color: rgba($sidebar-selected-item, 0.1);
                            > span {
                                color: $sidebar-selected-item;
                            }
                        }
                        &._delete {
                            width: 2.75rem;
                            height: 2.75rem;
                            line-height: 2.75rem;
                            border-radius: 50%;
                            background-color: rgba($sidebar-selected-item, 0.1);
                            color: $sidebar-selected-item;
                            padding: 0;
                            text-align: center;
                            transition: all 0.4s;
                            span {
                                vertical-align: middle;
                                color: $sidebar-selected-item;
                            }
                            &:hover {
                                border-radius: 50%;
                                color: $white;
                                background-color: rgba($sidebar-selected-item, 1);
                                span {
                                    color: $white;
                                }
                            }
                        }
                    }
                }
            }
            .edit_template_bts {
                display: flex;
                flex: 0 0 100%;
                justify-content: flex-end;
                .cancel-edit-button {
                    background-color: #e0e0e0 !important;
                    color: black !important;
                }
            }
            .submit_action {
                flex: 0 0 auto;
                display: flex;
                .submit_btn {
                    padding-right: 0;
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                    line-height: 32px;
                    @media screen and (max-width: 1366px) {
                        font-size: 12px;
                        padding-left: 10px;
                    }
                }
                .schedule_btn {
                    padding-left: 0.75rem;
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                    min-width: auto;
                    line-height: 32px;
                    @media screen and (max-width: 1366px) {
                        padding-left: 0.5rem;
                        font-size: 12px;
                        padding-right: 10px;
                    }
                }
            }
        }
        .tox {
            .tox-toolbar__primary {
                background: none;
                border-top: 1px solid #d6d7d9 !important;
            }
            .tox-editor-header {
                background-color: $white;
            }
            &.tox-tinymce {
                border: 1px solid #d6d7d9;
                border-radius: 0 0 10px 10px;
                border-top-width: 0;
            }
            .tox-tbtn {
                margin: 2px 2px 3px;
                width: 28px;
                height: 28px;
                color: #8d8d8d;
                &.tox-tbtn--enabled,
                &:hover {
                    border-radius: 6px;
                    background-color: rgba($sidebar-selected-item, 0.1);
                    color: $sidebar-selected-item;
                }
                .tox-icon {
                    position: relative;
                }
            }
            .tox-tbtn[title='Formatting'] {
                .tox-icon {
                    font-size: 15px;
                }
            }
            .tox-tbtn[title='Insert/edit link'] {
                .tox-icon {
                    font-size: 20px;
                }
            }
            .tox-tbtn[title='Emoticons'] {
                font-weight: 600;
                .tox-icon {
                    font-size: 17px;
                }
            }
            .tox-tbtn[title='Insert/edit image'] {
                font-weight: 600;
                .tox-icon {
                    font-size: 16px;
                }
            }
        }
        .perfectScrollbar {
            overflow: auto;
            max-height: calc(100% - 20px);
            height: calc(100% - 20px);
            padding: 0 1.125rem 1rem;
        }
        .form_row {
            display: flex;
            ._col {
                flex: 0 0 50%;
                display: inline-flex;
                max-width: calc(50% - 0.25rem);
                &:first-child {
                    margin-right: 0.5rem;
                }
                .form_group,
                &.full_width {
                    flex: 0 0 100%;
                    max-width: 100%;
                }
            }
            .relatedto_select {
                width: calc(100% - 84px);
            }
            .custom_select {
            }
            .opportunity_select {
                width: calc(100% - 0px) !important;
            }
            .clients_select {
                width: calc(100% - 0px) !important;
            }
            .lead_select {
                width: calc(100% - 0px) !important;
            }
            .project_select,
            .tickets_select {
                width: calc(100% - 0px) !important;
            }
        }
    }
    &.expanded_view {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        .compose_email {
            border-radius: 10px;
        }
    }
    &.h-100 {
        height: 100%;
    }
    tag-input-dropdown {
        .name {
            color: $sidebar-selected-item !important;
            font-weight: 600;
            height: auto;
            line-height: 1.2;
            font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
            font-size: 14px;
            letter-spacing: 0px;
        }
        .email {
            color: #333333 !important;
            font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
            letter-spacing: 0px;
        }
    }
    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
    .mat-checkbox-indeterminate.mat-accent .mdc-checkbox__background,
    .mat-mdc-checkbox-checked.mat-accent .mdc-checkbox__background {
        background-color: $sidebar-selected-item !important;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
    .mat-mdc-checkbox-checked .mdc-checkbox__background,
    .mat-checkbox-indeterminate .mdc-checkbox__background {
        background-color: $sidebar-selected-item !important;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
    .mat-mdc-checkbox:not(.mat-checkbox-disabled) .mat-checkbox-ripple .mat-ripple-element {
        background-color: $sidebar-selected-item !important;
    }
}

.tox .tox-collection--grid .tox-collection__group {
    width: 100%;
}

.compose_box_expanded {
    position: relative;
    .mailboxDetailPanel {
        position: absolute !important;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        z-index: 99;
        .compose_email {
            border-radius: 10px;
        }
    }
}

.position_static {
    position: static !important;
}

body .tox-toolbar__overflow {
    .tox {
        .tox-tbtn {
            height: 28px;
            width: 28px;
            svg {
                color: #8d8d8d;
            }
            &:hover {
                background: rgba($sidebar-selected-item, 0.1);
                color: $sidebar-selected-item;
                svg {
                    color: $sidebar-selected-item;
                }
            }
        }
    }
}

// Mailbox setting Page

.mailbox_setting_wrapper {
    padding: 1.5rem 1.25rem;
    height: 100%;
    background-color: #f9f9f9;
    ._title {
        padding-left: 1.25rem;
        margin-bottom: 1.25rem;
        margin-top: 0.25rem;
        position: relative;
        padding-right: 4rem;
        h4 {
            font-size: 1.5rem;
            color: $sidebar-selected-item;
            font-weight: 600;
            margin-top: 0;
            margin-bottom: 0;
        }
        .btn_close {
            position: absolute;
            right: 0;
            top: -5px;
            background-color: rgba($sidebar-selected-item, 0.1);
            color: $sidebar-selected-item;
            width: 2.75rem;
            height: 2.75rem;
            border-radius: 50%;
            text-align: center;
            line-height: 2.75rem;
            cursor: pointer;
            font-size: 1.5rem;
            transition: all 0.4s;
            &:hover {
                background-color: $sidebar-selected-item;
                color: $white;
            }
            i {
                vertical-align: middle;
            }
        }
    }
    .setting_wrapper {
        // padding: 0.875rem;
        background-color: #fff;
        border-radius: 10px 10px 0px 0px;
        height: calc(100% - 16px);
        .group_title {
            color: #333333;
            font-size: 1.4rem;
            font-weight: 600;
            margin-top: 0;
        }
        .field_label {
            font-weight: 500;
            font-size: 1.25rem;
            margin-bottom: 0.75rem;
        }
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        .mat-form-field-appearance-outline .mat-form-field-label,
        .green-light .mat-form-field-appearance-outline .mat-mdc-form-field-hint {
            color: rgba(51, 51, 51, 0.4);
        }
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        .mat-form-field-appearance-outline .mat-form-field-outline {
            color: #ebebeb;
        }
        .mat-form-field-appearance-outline .mat-mdc-form-field-infix {
            padding: 1rem 0 1.25rem 0;
            border-top: 0.75rem solid transparent;
        }
        .field_group {
            border-bottom: 1px dashed #d6d7d9;
            padding-bottom: 1.25rem;
            margin-bottom: 1.5rem;
            .row {
                margin-right: -10px;
                margin-left: -10px;
                .col-md-6,
                .col-md-3 {
                    padding-left: 10px;
                    padding-right: 10px;
                }
            }
            &._no_border {
                border-bottom: 0px dashed #d6d7d9;
            }
            &.no_padding {
                padding-bottom: 0rem;
            }
        }
        .tox-tinymce {
            border: 1px solid #ebebeb;
            border-radius: 10px;
        }
        .tox .tox-toolbar,
        .tox .tox-toolbar__overflow,
        .tox .tox-toolbar__primary {
            border-bottom: 1px solid #ebebeb;
            background: none;
        }
        .tox:not([dir='rtl']) .tox-toolbar__group:not(:last-of-type) {
            border-right: 1px solid #ebebeb;
        }
        .tox .tox-tbtn {
            height: 24px;
            width: 24px;
            font-size: 13px;
        }
        .tox .tox-tbtn--select {
            width: auto;
        }
        .tox .tox-statusbar {
            border-top: 0px solid #ccc;
            .tox-statusbar__text-container {
                display: none;
            }
        }
        .tox .tox-statusbar__resize-handle {
            padding-bottom: 2px;
            padding-right: 2px;
            svg {
                fill: #707070;
            }
        }
        .perfectScrollbar {
            overflow: auto;
            max-height: calc(100% - 0px);
            height: calc(100% - 0px);
            padding: 0.875rem 10px;
        }
        .btns_wrapper {
            > button {
                border-radius: 20px;
                margin-right: 1.5rem;
                box-shadow: none;
                &.btn_cancel {
                    margin-right: 0;
                    background-color: #e0e0e0;
                    color: #707070;
                }
                &.btn_tetsing {
                    color: #fff;
                    background-color: #27c24c;
                }
                &.btn_save {
                    color: #fff;
                    background-color: $sidebar-selected-item;
                }
            }
        }
        .port_wrap {
            .mat-mdc-form-field {
                margin-bottom: 1rem;
                &:last-child {
                    margin-bottom: 0;
                }
            }
            .email_checkboxes {
                margin-bottom: 1rem;
                .mat-mdc-checkbox {
                    background-color: #ffffff;
                    border-radius: 10px;
                    color: #333333;
                    border: 1px solid #f0f0f0;
                    padding: 0.75rem 2rem;
                    margin-bottom: 1rem;
                    display: inline-block;
                    .mdc-checkbox {
                        display: none;
                    }
                    label {
                        line-height: 1;
                        width: 100%;
                        text-align: center;
                    }
                    &.mat-mdc-checkbox-checked,
                    &:hover {
                        border: 1px solid $sidebar-selected-item;
                        background-color: rgba($sidebar-selected-item, 0.1);
                        color: $sidebar-selected-item;
                    }
                    &:first-child {
                        margin-right: 1rem;
                    }
                }
            }
        }
        .test_email_wrap {
            .mat-mdc-form-field {
                width: calc(100% - 20px);
            }
            i {
                padding: 0.25rem;
                font-size: 1.5rem;
            }
        }
        ._preview_toggle {
            .mat-mdc-slide-toggle {
                height: 39px;
                line-height: 39px;
                .mdc-switch:enabled .mdc-switch__track::after {
                    background-color: rgba($sidebar-selected-item, 0.1);
                }
                /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
                &.mat-checked .mat-slide-toggle-thumb {
                    background-color: rgba($sidebar-selected-item, 1);
                }
            }
            label {
                font-weight: 500;
            }
            /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
            .mat-slide-toggle-content {
                font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
                font-size: 1.3rem;
            }
            /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
            .mat-slide-toggle-thumb {
                height: 17px;
                width: 17px;
            }
            /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
            .mat-slide-toggle-thumb-container {
                width: 17px;
                height: 17px;
                top: 0px;
            }
            /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
            .mat-slide-toggle-label .mat-slide-toggle-bar {
                background-color: #e0e0e0;
                width: 30px;
                height: 17px;
            }
            /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
            .mat-slide-toggle-thumb {
                background-color: rgba(0, 0, 0, 0.25);
                box-shadow: none;
            }
            /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
            .mat-mdc-slide-toggle.mat-checked .mat-slide-toggle-thumb-container {
                transform: translate3d(13px, 0, 0);
            }
        }
        /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
        .mat-mdc-checkbox-checked .mdc-checkbox__background,
        .mat-checkbox-indeterminate .mdc-checkbox__background {
            background-color: $sidebar-selected-item !important;
        }
        /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
        .mat-mdc-checkbox:not(.mat-checkbox-disabled) .mat-checkbox-ripple .mat-ripple-element {
            background-color: $sidebar-selected-item !important;
        }
        .signature_checkbox {
            .mat-mdc-checkbox {
                font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
                .field_label {
                    font-size: 1.3rem;
                }
                .mdc-checkbox {
                    margin-right: 5px;
                }
                /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
                .mat-checkbox-layout {
                    line-height: 39px;
                }
            }
        }
        .password_field {
            position: relative;
            i {
                position: absolute;
                right: 16px;
                top: 50%;
                transform: translateY(-50%);
                color: #a5a5a5;
                cursor: pointer;
                padding: 0.25rem;
                background-color: #fff;
            }
        }
    }
}

.w-100 {
    width: 100%;
}

.h-100 {
    height: 100%;
}

.shortcut_keys {
    cursor: pointer;
    i {
        font-size: 2rem;
        color: #a5a5a5;
        vertical-align: middle;
    }
}
