/*

File: scss
*/

/*******************
 Topbar
*******************/
mat-toolbar {
    box-shadow: 1px 0px 10px rgba(0, 0, 0, 0.5);

    &.topbar {
        padding: 0px 5px;
        height: 70px;
    }

    .search-bar select {
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        padding: 6px 10px;
        color: #7d7d7d;
    }
    .search-bar input {
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        padding: 6px 10px;
        border: none;
    }
    .search-bar button {
        margin-left: -39px;
        color: black;
    }
    .app-search {
        display: none;
        width: 100%;
        top: 0px;
        left: 0px;
        z-index: 1;
        position: absolute;
        .form-control {
            line-height: 42px;
        }
        .cl-srh-btn {
            position: absolute;
            top: 12px;
            right: 15px;
            font-size: 15px;
            color: $bodytext;
            cursor: pointer;
        }
    }
    .profile-pic {
        width: 40px !important;
        height: 40px !important;
        border-radius: 100%;
        position: absolute;
        top: 0;
        left: 5px;
    }
}
/*******************
 Topbar
*******************/
.topbar {
    position: relative;
    /* Make sure the toolbar will stay on top of the content as it scrolls past. */
    z-index: 2;
}

.example-sidenav-container {
    /* When the sidenav is not fixed, stretch the sidenav container to fill the available space. This
     causes `<mat-sidenav-content>` to act as our scrolling element for desktop layouts. */
    flex: 1;
}

.example-sidenav-container {
    /* When the sidenav is fixed, don't constrain the height of the sidenav container. This allows the
     `<body>` to be our scrolling element for mobile layouts. */
    flex: 1 0 auto;
}

.headerComponent input,
.headerComponent select {
    font: 400 13.3333px Arial;
}

.navbar-header.sidebar-navbar-brand a {
    padding: 0px;
    font-size: 30.4px !important;
}

#headLeft {
    position: absolute;
    right: 0;
    height: 42px;
    display: flex;
}

/*************************/
.topbar.telative .header_site_name {
    font-size: 16px;
}

.header_search_call {
    margin-left: 10px;
    width: 100%;
}

.search-bar {
    float: left;
    width: 68%;
    margin-left: 10px;
}

.search-bar .search_form {
    width: 100%;
    display: flex;
    align-items: center;
}

.header_site_name {
    display: contents;
    .mat-mdc-icon-button {
        width: 30px;
    }
    mat-label {
        width: 30%;
        word-break: break-all;
        white-space: pre-line;
        line-height: 25px;
        max-height: 50px;
        padding-left: 10px;
    }

    mat-icon {
        position: relative;
        bottom: 3px;
    }
}

.header-search {
    color: #000;
    height: 42px;
    padding: 6px 10px;
}

.header-select {
    height: 42px;
    background-color: #fff;
    border: none;
    padding: 6px 10px;
}

.search_result.mat-mdc-option {
    font-size: 14px;
    height: 36px;
    display: block;
    color: #787878 !important;
    line-height: 36px;
    .mdc-list-item__primary-text {
        display: block;
    }
    a {
        color: #787878;
        display: block;
        &:hover,
        &:focus {
            text-decoration: none !important;
        }
    }
}

/*

TODO(mdc-migration): The following rule targets internal classes of autocomplete that may no longer apply for the MDC version.
*/
.mat-autocomplete-panel .header_text {
    background: #f7f7ed;
    margin: 0px;
    padding: 10px;
}

.details {
    &.no-data {
        padding: 20px 10px;
    }
}

.details p {
    margin: 0;
}

.mat-mdc-icon-button.mat-mdc-button-base {
    width: 40px;
    height: 40px;
}

.createNew-btn i {
    font-size: 20px;
    position: relative;
    bottom: 5px;
}

.search_result .text_title {
    color: #000;
}

.global_search_visibility {
    pointer-events: none;
    height: 20px;
    width: 20px !important;
    padding: 0 !important;
    min-width: 25px;
    float: right;
    margin-right: 10px;
    box-shadow: unset !important;
    background: none;
}

.global_search_visibility .mat-icon {
    line-height: 0px;
    height: 0;
    vertical-align: super;
    font-size: 15px;
    color: blue;
}

.mat-mdc-tab-header-pagination-controls-enabled .mat-mdc-tab-header-pagination {
    display: none !important;
}

@media (min-width: 1601px) {
    .search-bar .header-search {
        width: 65%;
    }

    .search-bar.headerComponent .header-select {
        width: 18%;
    }
    .search-bar {
        margin-left: 10%;
    }
}
@media (max-width: 1600px) {
    .search-bar .header-search {
        width: 85%;
    }

    .search-bar.headerComponent .header-select {
        width: 18%;
    }
}

@media (min-width: 1250px) and (max-width: 1400px) {
    .search-bar.headerComponent .header-select {
        width: 20%;
    }
    .search-bar .header-search {
        width: 73%;
    }
}
@media (min-width: 1150px) and (max-width: 1250px) {
    .search-bar.headerComponent .header-select {
        width: 20%;
    }
    .search-bar .header-search {
        width: 65%;
    }
}
@media (min-width: 1050px) and (max-width: 1150px) {
    .search-bar.headerComponent .header-select {
        width: 20%;
    }
    .search-bar .header-search {
        width: 55%;
    }
}
@media (max-width: 1050px) {
    .search-bar.headerComponent .header-select {
        width: 20%;
    }
    .search-bar .header-search {
        width: 46%;
    }
}

.search-autocomplete {
    max-height: 100% !important;
    min-width: calc(100% + 600px) !important;
    transform: translateX(-300px);
    border-radius: 10px !important;
    padding: 0 !important;
    @media (max-width: 1600px) {
        min-width: calc(100% + 500px) !important;
        transform: translateX(-250px);
    }
    .mat-mdc-tab {
        height: 40px;
    }
    .mat-mdc-tab-body-content {
        min-height: 200px;
        max-height: calc(100vh - 250px) !important;
    }
}
