.mat-mdc-slider {
    height: 35px;
    width: 100% !important;
    margin: 0 !important;

    .mdc-slider__thumb {
        height: 34px;
        width: 34px;

        .mat-ripple {
            border-radius: 50px;
        }
    }

    .mdc-slider__thumb-knob {
        height: 14px !important;
        width: 14px !important;
        border-width: unset !important;
    }

    .mdc-slider__track {
        height: 2px !important;

        &--inactive,
        &--active {
            height: 2px !important;
        }
    }

    .mdc-slider__value-indicator {
        opacity: 1 !important;
    }
}
