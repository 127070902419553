/*

File: scss
*/
// Variables
@use '@angular/material' as mat;

// @import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,600,700');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300..800&display=swap');

$bodyfont: 'Open Sans', sans-serif; //'Open Sans', sans-serif;
$headingfont: 'Open Sans', sans-serif; //'Open Sans', sans-serif;
$black-87-opacity: rgba(black, 0.87);
//@import '@angular/material/theming';

/*Material Theme Colors*/
// define a real custom palette (using http://mcg.mbitson.com)
$bv-brand: (
    50: #ecebf5,
    100: #d0cce7,
    200: #b1abd7,
    300: #9289c6,
    400: #7a6fba,
    500: #6356ae,
    600: #5b4fa7,
    700: #51459d,
    800: #473c94,
    900: #352b84,
    A100: #cfc9ff,
    A200: #a196ff,
    A400: #7363ff,
    A700: #5c4aff,
    contrast: (
        50: $black-87-opacity,
        100: $black-87-opacity,
        200: $black-87-opacity,
        300: $black-87-opacity,
        400: $black-87-opacity,
        500: white,
        600: white,
        700: white,
        800: white,
        900: white,
        A100: $black-87-opacity,
        A200: $black-87-opacity,
        A400: $black-87-opacity,
        A700: white,
    ),
);

// Standard Media Query Variables
$device-xs: 320px; // Extra small devices (portrait phones)
$device-sm: 500px; // Small devices (landscape phones)
$device-md: 768px; // Medium devices (tablets)
$device-lg: 1024px; // Large devices (desktops)
$device-xl: 1200px; // Extra large devices (large desktops)

$primary: mat.m2-define-palette($bv-brand, 600, 50, 800);
$accent: mat.m2-define-palette(mat.$m2-green-palette, 300);
$warn: mat.m2-define-palette(mat.$m2-pink-palette, 500);
$theme: mat.m2-define-light-theme(
    (
        color: (
            primary: $primary,
            accent: $accent,
            warn: $warn,
        ),
        typography: mat.m2-define-typography-config(),
        density: 0,
    )
);
$background: map-get($theme, background);
$foreground: map-get($theme, foreground);

@include mat.core();

$custom-typography: mat.m2-define-typography-config(
    $font-family: 'Open Sans, sans-serif',
);

// Override the typography in the core CSS.
@include mat.all-component-typographies($custom-typography);

// Default Theme
@include mat.all-component-themes($theme);

// Dark Theme

/*Theme Colors*/

$topbar: #2196f3;
$sidebar: #fff;
$sidebar-white: #e5edef;
$sidebar-alt: #f2f6f8;
$bodycolor: #eef5f9;
$headingtext: #455a64;
$bodytext: #000000;
$sidebar-text: #607d8b;
$sidebar-icons: #99abb4;

$font-16: 16px;

$light-text: #a6b7bf;
$themecolor: #1e88e5;
$themecolor-alt: #26c6da;
$themecolor-dark: #028ee1;

/*bootstrap Color*/
$danger: #fc4b6c;
$success: #009220;
$warning: #ffb22b;
$primary: #5b4fa7;
$info: #1e88e5;
$inverse: #2f3d4a;
$muted: #99abb4;
$dark: #263238;
$light: #f2f4f8;
$extra-light: #ebf3f5;
$secondary: #727b84;

/*Light colors*/
$light-danger: #f9e7eb;
$light-success: #e8fdf8;
$light-warning: #fff8ec;
$light-primary: #f1effd;
$light-info: #e3f3fd;
$light-inverse: #f6f6f6;
$light-megna: #e0f2f4;

$danger-dark: #e6294b;
$success-dark: #005c14;
$warning-dark: #e9ab2e;
$primary-dark: #6352ce;
$info-dark: #028ee1;
$red-dark: #d61f1f;
$inverse-dark: #232a37;
$dark-transparent: rgba(0, 0, 0, 0.05);

/*Normal Color*/
$white: #ffffff;
$red: #fb3a3a;
$yellow: #d1bf1d;
$purple: #7460ee;
$blue: #1778c7;
$megna: #00897b;

/*Extra Variable*/
$rgt: right;
$lft: left;
$border: rgba(120, 130, 140, 0.13);
$table-border: #f3f1f1;
$card-brd: #d7dfe3;
$dark-text: #848a96;
$radius: 4px;
$form-brd: #d9d9d9;

//colors used in dashboard tiles
$border-tiles: #ddd;
$custom-green: #38b456;
$custom-pink: #f44242;
$dashboard-title: #f2f4f6;
