/*@import url("../node_modules/bootstrap/dist/css/bootstrap.min.css");*/
@import 'bootstrap/dist/css/bootstrap.min.css';
@import '@ng-select/ng-select/themes/default.theme.css';
@import 'open-iconic/font/css/open-iconic-bootstrap.css';
/* @import "@ng-select/ng-select/themes/material.theme.css"; */
/* Tailwind init */
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --sidebar-selected-item: #ec2121;
}

.hold-transition .content-wrapper,
.hold-transition .right-side,
.hold-transition .main-footer,
.hold-transition .main-sidebar,
.hold-transition .left-side,
.hold-transition .main-header .navbar,
.hold-transition .main-header .logo,
.hold-transition .menu-open .fa-angle-left {
    /* Fix for IE */
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
}

/* Content */
.content {
    min-height: 250px;
    padding: 15px;
    margin-right: auto;
    margin-left: auto;
}

/*
 * Page: Lock Screen
 * -----------------
 */
/* ADD THIS CLASS TO THE <BODY> TAG */
.lockscreen {
    background: #d2d6de;
}

.lockscreen-logo {
    font-size: 35px;
    text-align: center;
    margin-bottom: 25px;
    font-weight: 300;
}

.lockscreen-logo a {
    color: #444;
}

.lockscreen-wrapper {
    max-width: 400px;
    margin: 10% auto 0;
}

/* User name [optional] */
.lockscreen .lockscreen-name {
    text-align: center;
    font-weight: 600;
}

/* Will contain the image and the sign in form */
.lockscreen-item {
    border-radius: 4px;
    padding: 0;
    background: #fff;
    position: relative;
    margin: 10px auto 30px auto;
    width: 290px;
}

/* User image */
.lockscreen-image {
    border-radius: 50%;
    position: absolute;
    left: -10px;
    top: -25px;
    background: #fff;
    padding: 5px;
    z-index: 10;
}

.lockscreen-image > img {
    border-radius: 50%;
    width: 70px;
    height: 70px;
}

/* Contains the password input and the login button */
.lockscreen-credentials {
    margin-left: 70px;
}

.lockscreen-credentials .form-control {
    border: 0;
}

.lockscreen-credentials .btn {
    background-color: #fff;
    border: 0;
    padding: 0 10px;
}

.lockscreen-footer {
    margin-top: 10px;
}

/*
 * Page: Login & Register
 * ----------------------
 */
.siteLogo {
    padding: 15px 25px;
}

.siteLogo img {
    height: 60px;
}

.login_main,
.register-page,
.forget-password {
    min-height: 100vh;
    background-size: cover;
    background: #ffffff no-repeat center;
}

.login_main .footer-box-body,
.forget-password .footer-box-body {
    text-align: center;
    border-top: 0;
    color: #666;
    font-weight: 400;
    position: absolute;
    bottom: 20px;
}

.login_page_content {
    margin-top: 10%;
    text-align: center;
    color: #ffffff;
}

.login_page_content h1 {
    font-size: 48px;
    line-height: 65px;
    /* font-style: italic; */
}
.login_page_content h4 {
    line-height: 24px;
    font-size: 22px;
}
.heading_txt {
    font-size: 18px;
    font-weight: 600;
}

.login_main .mat-mdc-input-element,
.register-page .mat-input-element {
    font-size: 14px;
}

.login-box-body .form-control-feedback,
.register-box-body .form-control-feedback {
    color: #777;
}

.login-box-msg,
.register-box-msg {
    margin: 0;
    text-align: center;
    padding: 0 20px 20px 20px;
}
.login-box .proceedBtn,
.register-box-body .proceedBtn {
    color: white;
    background: #ff0054;
    border: #ff0054;
    text-transform: uppercase;
    font-weight: 600;
    padding: 8px;
    font-size: 16px;
}

.social-auth-links {
    margin: 10px 0;
}

/*
 * Page: 400 and 500 error pages
 * ------------------------------
 */
.error-page {
    width: 600px;
    margin: 20px auto 0 auto;
}

@media (max-width: 991px) {
    .error-page {
        width: 100%;
    }
}

.error-page > .headline {
    float: left;
    font-size: 100px;
    font-weight: 300;
}

@media (max-width: 991px) {
    .error-page > .headline {
        float: none;
        text-align: center;
    }
}

.error-page > .error-content {
    margin-left: 190px;
    display: block;
}

@media (max-width: 991px) {
    .error-page > .error-content {
        margin-left: 0;
    }
}

.error-page > .error-content > h3 {
    font-weight: 300;
    font-size: 25px;
}

@media (max-width: 991px) {
    .error-page > .error-content > h3 {
        text-align: center;
    }
}

html .page-wrapper .page-content {
    background-color: #f2f3f5;
    padding: 0px !important;
}

.content-header {
    padding: 10px 23px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 425px) {
    .content-header {
        padding: 10px 15px;
    }
}

.content-header h1 {
    font-size: 20px;
    margin: 0px;
}

.content-header h1 i {
    padding-right: 5px;
}
.mat-mdc-menu-content:not(:empty) {
    padding: 12px !important;
}

@media (min-width: 992px) {
    .login_main,
    .register-page,
    .forget-password {
        background-image: url('./assets/images/background/milagroBackground.png');
    }
}
@media (min-width: 1600px) {
    .login_page_content h4 {
        line-height: 22px;
        font-size: 20px;
    }

    .login_page_content h1 {
        font-size: 45px;
        line-height: 60px;
    }
}

@media (max-width: 1440px) {
    .login_page_content h1 {
        font-size: 40px;
        line-height: 55px;
    }
}

@media (max-width: 1024px) {
    .search-bar .header-search {
        width: 100px;
    }
    .create-new {
        display: none !important;
    }

    .login_page_content h1 {
        font-size: 38px;
    }

    .login_page_content h4 {
        font-size: 19px;
    }
}
@media (max-width: 991px) {
    .error-page {
        width: 100%;
    }

    .error-page > .error-content > h3 {
        text-align: center;
    }

    .error-page > .error-content {
        margin-left: 0;
    }

    .error-page > .headline {
        float: none;
        text-align: center;
    }
    .siteLogo {
        text-align: center;
    }

    .login_page_content,
    .login_main .login-box,
    .login-box.forget_pass {
        position: unset;
        margin: 0 auto;
    }

    .login_main .footer-box-body,
    .forget-password .footer-box-body {
        display: none;
    }

    .login_main,
    .register-page,
    .forget-password {
        background-image: url('assets/images/background/milagroLogin.png');
        padding: 10px;
    }

    .login_page_content {
        padding: 35px 0px;
    }
    .login_page_content h1 {
        font-size: 35px;
        line-height: 45px;
        margin-top: 0;
    }

    .login_page_content h4 {
        line-height: 18px;
        font-size: 18px;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .login-box .mat-form-field-flex {
        background: #fff;
        border-radius: 6px;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .login-box .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-wrapper {
        margin: 0;
    }
}

@media (max-width: 768px) {
    .login_main .login-box-body,
    .login-box.forget_pas {
        width: 65%;
        margin-top: 20px;
    }
}

@media (max-width: 567px) {
    .login_page_content h1 {
        font-size: 25px;
        line-height: 35px;
    }
    .login-logo,
    .register-logo {
        font-size: 30px;
    }
    .login_main .login-box,
    .login-box.forget_pass {
        width: 80%;
        float: none;
    }
    .register-box {
        margin-top: 0px;
    }
    .login_main {
        min-height: 110vh;
    }
}

@media (max-width: 480px) {
    .login_main .login-box,
    .login-box.forget_pass {
        width: 100%;
        padding: 0 5px;
    }
    .login_page_content h4 {
        font-size: 16px;
        line-height: 22px;
    }
    .login_page_content {
        padding: 25px 5px;
    }
    .login_main {
        min-height: 130vh;
    }
}

.smartwait_widgets.chartjs-render-monitor {
    height: 300px !important;
}

@media (min-width: 1700px) {
    .smartwait_widgets.chartjs-render-monitor {
        height: 389px !important;
    }
}

@media (max-width: 375px) {
    .smartwait_widgets.chartjs-render-monitor {
        height: 300px !important;
    }
}

.icon-trash:before {
    content: '\e908';
}
.icon-full-screen:before {
    content: '\e909';
}

.tox .tox-menu.tox-collection.tox-collection--list {
    max-height: 324px !important;
}
.tox .tox-collection__item-icon {
    height: auto !important;
    width: auto !important;
}
.tox-collection__group {
    flex-direction: column;
}

.dialog-padding .mat-mdc-dialog-container {
    padding: 0 !important;
}

.ng-scroll-content {
    contain: none !important;
}

::ng-deep.form-field-shrink-width {
    .mat-mdc-form-field {
        width: 100% !important;
    }

    .mat-mdc-form-field-infix {
        width: 0 !important;
    }
}
